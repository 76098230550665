import { Component, ComponentFactoryResolver, OnInit, ViewChild,OnDestroy } from "@angular/core";
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup,UntypedFormControl } from "@angular/forms";
import { Select2OptionData } from "ng-select2";
import { ToastrService } from "ngx-toastr";
import { Options } from 'select2';
import { DataService } from "src/app/core/shared/data.service";
import { notificationMessage } from "src/app/mocks/nofification.message";
import { AdminService } from "../admin-task/admin.service";
import { ExportToCsv } from 'export-to-csv';
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { type } from "os";

//added on 5/5/2022
import {QueryModel} from './query-model';
import {TableKVP} from './table-kvp';
import { FilterModel } from "./query-filter-model";
import {  Subscription } from 'rxjs';

import { Validators } from '@angular/forms';
import { ItemsList } from "@ng-select/ng-select/lib/items-list";
import { stringify } from "querystring";

import { ReactiveFormsModule } from '@angular/forms';

@Component({
  selector: "app-query",
  templateUrl: "./query.component.html",
  styleUrls: ["./query.component.css"],
})
export class QueryComponent implements OnInit,OnDestroy {
  @ViewChild('closebutton') closebutton;


  public options: Options;

  pagination: any = { start: 0, end: 24 };
  paResultSetting = {};
  paResultData = [];
  selectedRows: any;
  selectedIndex: number=-1;
  
  
  table: TableKVP[] = [{id:'CourtStatusAll_View',name:'CourtStatusAll_View'}];
  tables: TableKVP[] = [{id:'CourtStatusAll_View',name:'CourtStatusAll_View'}];
  
  field:any=[];
  queryTypeFlag = 'new';

  condition:any=[{name:"AND"},{name:"OR"}]
  whereFields: any[];
  operator:any=[{name:'='},{name:'!='},{name:'>'},{name:'>='},{name:'<='},{name:'<'},{name:'contains'}];
  value:any={};
  tableName: any="";
  queryName: string="";
  queryType: any = '';
  resultGrid: boolean = false;
  myQueriesLists: QueryModel[] = [];
  sharedQueriesLists: QueryModel[] = [];
  selectedTable: any = [];
  userInfo: any;
  btnMyQry: boolean;
  btnSharedQry: boolean;
  btnSaveCopy: boolean;
  btnCancelQuery: boolean;
  //data: any;
  data: QueryModel;

  //new 5/5/2022
  queryModel: QueryModel;
  queryModels: QueryModel[]=[];

  
  tableKVP:TableKVP[] = [];
  fieldKVP:TableKVP[] = [];

  form: UntypedFormGroup = this.formBuilder.group({
    queryTable: [],//this.formBuilder.array(this.tableKVP),
    queryCondition: []//this.formBuilder.array(this.tableKVP)
  });

  selTablesSubscription: Subscription;
  selQueriesSubscription: Subscription;
  getQueriesSubscription: Subscription;
  getWhereFieldsSubscription: Subscription;
  getWhereValuesSubscription: Subscription;
  getQueryDetailsSubscription: Subscription;
  getSaveMyQuerySubscription: Subscription;
  getSaveSharedQuerySubscription: Subscription;


  pageNumber: number = 1;
  maxPages: number = 10;
  rowsPerPage: number = 300;
  searchError: string = "";
  pageForm: UntypedFormGroup;
  pageForm2: UntypedFormGroup;
  queryResultsRowsCount: number=0;

  progressBarFullTeamValue: number = 0;
  progressBarFullTeamCounter: number = 0;
  uploadDuration: number = 0;
  remainingFullTeams: string ="Not started yet";
  progressBarFullTeamLabel: string = "";
  cancelQueryLoad: boolean = false;
  queryRowsCountMessage: string = "";

  containsFieldsData: any="";
  newForm:any;

  constructor(
    private DataService: DataService,
    private formBuilder: UntypedFormBuilder,
    private adminService: AdminService,
    private toastr: ToastrService,
    private modalService: NgbModal
  ) {
    console.log('query page accessed');
   }


  ngOnInit(): void {
    this.userInfo = JSON.parse(localStorage.getItem('userInfo'))
    this.DataService.setPageTitle("Query");
    //enabled section  begin: 5/6/2022
    this.selTablesSubscription = this.adminService.getSelectTables().subscribe((data: any) => {
      //console.log('this.adminService.getSelectTables data=',data)

      localStorage["QueryRowsPerPage"]=this.rowsPerPage;
      this.rowsPerPage=localStorage["QueryRowsPerPage"]||100;

      var tableKeyValue: {
        id: string,
        name: string
      }
      var tableData:  typeof tableKeyValue [] = [];
      data.values.forEach((item, i) => {
        var id = item;
        var value = item.replace("bis", "");
        value = value.replace(/_/gi, " ");
        //if(i == 0)      
        {
          tableData.push({
            id: id,
            name: value
          })
        }


      });
      //console.log('OnInit tableData=',tableData)
      //assign table
      this.tables = tableData;
      tableData.forEach((item)=>
      {
 
        if(item.name=='biscourtstatus')
          this.table.push(item);
      });
      //console.log('OnInit this.table=',this.table)
 
    });
    //enabled section  end: 5/6/2022


    this.selQueriesSubscription = this.adminService.getSelectFields(this.table[0].id).subscribe((data: any) => {
      
      //console.log("query.component onInit getSelectFields() data", data);
      var fieldData = []
      data.values.forEach(item => {
        var id = item;
        var value = item.replace("bis", "");
        value = value.replace(/_/gi, " ");
        fieldData.push({
          id: id,
          name: value
        })
      });
      this.field = fieldData;
    });

    this.getQueriesSubscription = this.adminService.GetQueries().subscribe((data: any) => {
       //console.log("query.component this.adminService.GetQueries() data === "); console.log(data);

       this.queryModels = data;

       //console.log("query.component this.adminService.GetQueries() this.queryModels === "); console.log(this.queryModels);
      
      this.queryModels.forEach(qModel=>{

      })

      var myQueries: QueryModel[] = [], sharedQueries:QueryModel[] = [];
      this.queryModels.forEach(element => {


        if(element.QueryType == "Private") {


          if(element.UserName == this.userInfo.unique_name) {
            myQueries.push({
            QueryName: element.QueryName,
            UserName: element.UserName,
            QueryType: element.QueryType,
            TableName: element.TableName,
            Fields: element.Fields,
            Criteria: element.Criteria

          })
          }
        } else {

          sharedQueries.push({
            QueryName: element.QueryName,
            UserName: element.UserName,
            QueryType: element.QueryType,
            TableName: element.TableName,
            Fields: element.Fields,
            Criteria: element.Criteria
          })
        }
      });
      this.myQueriesLists = myQueries;
      this.sharedQueriesLists = sharedQueries;


    });
    this.getWhereFieldsSubscription = this.adminService.getWhereFields().subscribe((data: any) => {
      var whereFieldsData = []
      data.fields.forEach(item => {
        var id = item;
        //var value = item;
        var value = item.replace("bis", "");
        value = value.replace(/_/gi, " ");
        whereFieldsData.push({
          id: id,
          name: value
        })
      });
      this.whereFields = whereFieldsData;
    });
    
    this.form = this.formBuilder.group({
      queryTable: [],//this.formBuilder.array(this.tableKVP),
      queryCondition: []//this.formBuilder.array(this.tableKVP)
    });
          //pageform for page number
          this.pageForm = new UntypedFormGroup({
            pageInput: new UntypedFormControl('',
            [Validators.required, Validators.pattern(/^[0-9]+$/)])
            ,
            pageInput2: new UntypedFormControl('',
            [Validators.required, Validators.pattern(/^[0-9]+$/)])
          });
          //pageform for rows per page
          this.pageForm2 = new UntypedFormGroup({
            pageInput2: new UntypedFormControl('',
            [Validators.required, Validators.pattern(/^[0-9]+$/)])
          });
    // this.addQueryTable();
    // this.addQueryCondition();
    this.newquery();
  }//onInit

  ngOnDestroy() {
      if(this.selTablesSubscription && !this.selTablesSubscription.closed) this.selTablesSubscription.unsubscribe();  
      if(this.selQueriesSubscription && !this.selQueriesSubscription.closed) this.selQueriesSubscription.unsubscribe(); 
      if(this.getQueriesSubscription && !this.getQueriesSubscription.closed) this.getQueriesSubscription.unsubscribe();  
      if(this.getWhereFieldsSubscription && !this.getWhereFieldsSubscription.closed) this.getWhereFieldsSubscription.unsubscribe();
      if(this.getWhereValuesSubscription && !this.getWhereValuesSubscription.closed) this.getWhereValuesSubscription.unsubscribe();   
      if(this.getQueryDetailsSubscription && !this.getQueryDetailsSubscription.closed) this.getQueryDetailsSubscription.unsubscribe(); 
      if(this.getSaveMyQuerySubscription && !this.getSaveMyQuerySubscription.closed) this.getSaveMyQuerySubscription.unsubscribe(); 
      if(this.getSaveSharedQuerySubscription && !this.getSaveSharedQuerySubscription.closed) this.getSaveSharedQuerySubscription.unsubscribe();   
  }//end of fx

  setRowsPerPage()
  {

  }
  
  addQueryTable() {
    console.log('...ADD QUERY CALLED...');
    // console.log('addQueryTable() called with this.aliasQueryTable=',this.aliasQueryTable)
    // console.log('addQueryTable() called with this.aliasQueryTable.controls=',this.aliasQueryTable.controls)
    var isValid = false;

    if(this.aliasQueryTable.controls)
    if(this.aliasQueryTable.controls.length > 0){
      var queryTable = this.aliasQueryTable.controls[this.aliasQueryTable.controls.length-1].value
      if(queryTable.table != null && queryTable.field != null) {
        isValid = true
      }
    }
    var tableTemp:TableKVP[] = [];
    var fieldTemp:TableKVP[] = [];

    if(this.aliasQueryTable.controls)
    if(isValid || this.aliasQueryTable.controls.length == 0) {
      this.aliasQueryTable.push(
        this.formBuilder.group({
          table: tableTemp,
          field: fieldTemp
        })
      );
    } else {
      this.toastr.error(
        notificationMessage.WHERE_ERROR,
        notificationMessage.ERROR
      );
    }
    //console.log('addQueryTable() exited with this.aliasQueryTable=',this.aliasQueryTable)
  }
  removeQueryTable(i) {
    console.log('...REMOVE QUERY CALLED...');

    if(this.aliasQueryTable.controls)
    if(this.aliasQueryTable.controls.length > 1 && i == 0) {
      this.aliasQueryTable.removeAt(i);
      // this.aliasQueryTable.at(0).get("field").reset();
    }else if(i != 0) {
      this.aliasQueryTable.removeAt(i);
    } else {
      this.aliasQueryTable.at(i).get("field").reset();
    }
  }
  onchangeQueryTable(event,index){
    //console.log('onChangeQueryTable() index=',index)
    //console.log('onChangeQueryTable() event=',event)

    // if(!event)
    // {
    //   //console.log('returned')
    //   return;
    // }
    // var tableName = event.id;
  }
  newquery() {
    this.progressBarFullTeamLabel = "";
    this.btnSaveCopy = true;
    this.btnMyQry = false;
    this.btnSharedQry = false;
    this.queryName = '';
    this.queryTypeFlag == "new  "
    this.resetForm()
  }
  resetForm() {
    // console.log('...RESET QUERY CALLED...');
    this.form = this.formBuilder.group({
      queryTable: this.formBuilder.array([]),
      queryCondition: this.formBuilder.array([])
    });

    this.paResultData = [];//added today 5/9/2022
    this.addQueryTable();
    this.addQueryCondition();
  }
  addQueryCondition() {
    
    var isValid = false;

    if(this.aliasQueryCondition.controls)
    if(this.aliasQueryCondition.controls.length > 0){
      var conditionValue = this.aliasQueryCondition.controls[this.aliasQueryCondition.controls.length-1].value
      if(this.aliasQueryCondition.controls.length == 1 && conditionValue.whereFields != null && conditionValue.operator != null && conditionValue.value !== null) {
        isValid = true
      } else if(conditionValue.condition != null && conditionValue.whereFields != null && conditionValue.operator != null && conditionValue.value !== null) {
        isValid = true;
      }
    }
    if(this.aliasQueryCondition.controls)
    if(isValid || this.aliasQueryCondition.controls.length == 0) {
      this.aliasQueryCondition.push(
        this.formBuilder.group({
          condition: [],
          whereFields: [],
          operator: [],
          value: [],
        })
      );
    } else {
      this.toastr.error(
        notificationMessage.WHERE_ERROR,
        notificationMessage.ERROR
      );
    }
  }
  removeQueryCondition(i) {

    if(this.aliasQueryCondition.controls)
    if(this.aliasQueryCondition.controls.length > 1 && i == 0) {
      this.aliasQueryCondition.removeAt(i);
      // this.aliasQueryTable.at(0).get("field").reset();
    }else if(i != 0) {
      this.aliasQueryCondition.removeAt(i);
    } else {
      this.aliasQueryCondition.at(i).get("condition").reset();
      this.aliasQueryCondition.at(i).get("whereFields").reset();
      this.aliasQueryCondition.at(i).get("operator").reset();
      this.aliasQueryCondition.at(i).get("value").reset();
    }
  }//end

  onContainSelect(event,index,control)
  {
    

    var controlName = "";
    var controlValue = "";

    if(control.controls.value.value && control.controls.value.value.id)
    {
      controlName = control.controls.value.value.name;
      controlValue = control.controls.value.value.id;
    }
    else if(control.controls.value.value)
    {
      controlName = '%'+control.controls.value.value+'%';
      controlValue = '%'+control.controls.value.value+'%';
    }
    var keyValueControl = {id:controlName,name:controlValue}
    control.controls.value.value = keyValueControl;

    if(control.controls.operator.value && control.controls.operator.value.name=='contains')
    {
      control.controls.operator.value.name='like'
    }

  }//end

  onchangeWhereFields(event,index, name) {

    var filedName;
    if(event =='') {
      if(name)
      filedName = name; 
      else
      filedName = '';
    } 
    else if(event)
    {
      filedName = event.id;
    }
    else
     {
      filedName = '';
    }
    if(String(filedName).length>0)
    this.getWhereValuesSubscription = this.adminService.getWhereValues(filedName).subscribe((data: any) => {
      // console.log(data,"----getvalues")
      var fieldData = []
      data.values.forEach(item => {
        if(item) {
          var id = item;
          var value = item.replace("bis", "");
          value = value.replace(/_/gi, " ");
          fieldData.push({
            id: id,
            name: value
          })
        }
      });
      this.value[index] = fieldData;
    });//end of getWhereValues api

    this.aliasQueryCondition.at(index).get("value").reset();
  }//end of onChange fx

  get aliasQueryCondition() {
    
    return (<UntypedFormArray>this.form.get('queryCondition'));
  }
  get aliasQueryTable() {
    //console.log('aliasQueryTable this.table=',this.table);
    return (<UntypedFormArray>this.form.get('queryTable'));
    //return this.form.get('queryTable');
  }

  get aliasQueryTableControls() {

    return (<UntypedFormArray>this.form.get('queryTable')).controls;

  }


  
  dataBinding(type) {
 
    const queryTableData = this.aliasQueryTable.getRawValue();


    const queryConditions =  this.aliasQueryCondition.getRawValue();

    for(var indexN=0;indexN<queryConditions.length;indexN++)
    {
      if(indexN>0 && queryConditions[indexN].condition==null)
      {
        this.progressBarFullTeamValue = 0;
        this.progressBarFullTeamLabel = "";
        localStorage["QueryResultsRowsCount"] = 0;
        this.queryResultsRowsCount = 0;
        this.queryRowsCountMessage = 'Please fix where clause errors and Run again!';

        this.toastr.error(
          'Please select AND/OR condition where appropriate in the where clause!',
          notificationMessage.ERROR
        );
        return;
      }

    }


    var requestData = [], isValid = true;
    var fieldData: string[] = [], tableName:string = '', crieteria:FilterModel[] = [];


    queryTableData.forEach((item, i) => {


      if(item.table && item.field) {
        tableName = item.table.id;
        fieldData.push(item.field.id);
      } else {
        //return isValid = false;
      }

    })

    var val1 = this.aliasQueryCondition.at(0);
    var val2 = this.aliasQueryCondition.at(0).get("whereFields");

    
    queryConditions.forEach((item, i) => {

      var conditionValue: any;
      var operatorValue: any;
      if(item.operator&&item.operator.name && (item.operator.name=='like' || item.operator.name=='contains'))
      {
        if(item.value && item.value.id)
          conditionValue = '%'+item.value.id+'%';
        else
          conditionValue = '%'+item.value+'%';

        operatorValue = 'like';
      }
      else
      {
        if(item && item.value && item.value.id)
        conditionValue = item.value.id;

        if(item&&item.operator&&item.operator.name)
        operatorValue = item.operator.name;
      }

      
      if(i == 0 && item.operator && item.value && item.whereFields) {

        var condition = ''
        if(item.condition != null) {
          condition = item.condition.name
        }
        crieteria.push({
          "Field": item.whereFields.id,
          "ConditionalOperator": operatorValue,
          "Value": conditionValue,
          "LogicalOperator": condition
        })
      } else if(item.condition && item.operator && item.value && item.whereFields) {

        crieteria.push({
          "Field": item.whereFields.id,
          "ConditionalOperator": operatorValue,
          "Value": conditionValue,
          "LogicalOperator": item.condition.name
        })
      } else {
        //return isValid = false;
      }
    })

    if(type == 'saveAsCopy') {
      if(this.queryName == '' && this.queryType == '') {
        this.toastr.error(
          'please enter valid data',
          notificationMessage.ERROR
        );
        isValid = false;
      }
    }
    if(isValid) {
      this.data;
      if(type == 'runQuery') {


        this.data = {
          TableName: tableName,
          Fields: fieldData,
          Criteria: crieteria,
          UserName:"",
          QueryType:"",
          QueryName:""
        }

        this.runQuery(this.data);

      } else {
        if(this.queryName) {
          if(type == 'myQuery') {
            this.queryType ="Private"
          } else if(type == 'sharedQuery') {
            this.queryType ="Shared"
          }
          var userName = JSON.parse(localStorage.getItem('userInfo')).userName;
          this.data = {
            "UserName": userName,
            "QueryName": this.queryName,
            "QueryType": this.queryType,
            "TableName": tableName,
            "Fields": fieldData,
            "Criteria": crieteria
          }
          if(this.data.QueryType == 'Private') {
            var qryType = "myQuery"
          } else {
            qryType = "sharedQry"
          }
          this.saveQuery(this.data, qryType)
        } else {
          this.toastr.error(
            notificationMessage.QUERY_NAME,
            notificationMessage.ERROR
          );
        }
      }
    } else {
      this.toastr.error(
        notificationMessage.WHERE_ERROR,
        notificationMessage.ERROR
      );
      return true;
    }
    
  }//end of dataBinding()

  resultSection(resultData) {

    // console.log(resultData, "---resultSection(resultData)")
    var column = {}


    if(resultData)
    {

      this.queryResultsRowsCount = localStorage["QueryResultsRowsCount"];

    }

    
    var setting = {
      columns: column,
      attr: {
        class: "qry_result_view table table-hover table-bordered",
      },
      actions: {
        delete: false,
        add: false,
        edit: false,
      },
      pager: {
        perPage: 25,
        display: false,
      },
    };
    this.paResultSetting = setting;

    //this.paResultData =  resultData
  }//end resultSection

  resultMakeFinalSet(resultData) {


    this.paResultData =  resultData;
  
    if(this.paResultData.length>0)
    {
      //this.exportCSV();
      //console.log('downloading CSV...')
    }
  }//end of fx

  goToPage() {

    localStorage["QueryResultsRowsCount"] = 0;

    if (this.pageNumber < 1 ) {
      this.toastr.error(
        "Page number not valid!",
        notificationMessage.ERROR
      );
      return;
    }
    
    if (this.rowsPerPage < 1 || this.rowsPerPage > 1000 ) {
      this.toastr.error(
        "Rows per Page number not valid! Must be betwen 1 and 1000",
        notificationMessage.ERROR
      );
      return;
    }

    localStorage["QueryRowsPerPage"]=this.rowsPerPage;

    localStorage["QueryPageNumber"]=this.pageNumber;
    this.queryResultsRowsCount = localStorage["QueryResultsRowsCount"];
    
    if(this.data && this.data.TableName)
    this.dataBinding('runQuery');


  }//end

  cancelQuery()
  {
    console.log('query cancelled');
    this.cancelQueryLoad = true;   
    this.toastr.info(
      "Query data load is cancelled",
      notificationMessage.SUCCESS
    );

    setTimeout(() => {
      window.location.reload();
    }, 6000);

  }//end


  runQuery(data:QueryModel) {


    //console.log('query.component runQuery() data= ',data); 
    this.pageNumber = localStorage["QueryPageNumber"] || 1;
    this.rowsPerPage= localStorage["QueryRowsPerPage"] || 100;
    this.progressBarFullTeamValue =0;
    localStorage["QueryResultsRowsCount"] = 0;
    this.queryResultsRowsCount = 0;
    var setRowsCount: Boolean=false;
    this.cancelQueryLoad = false;   

    if((data.TableName=="" )&&this.queryName=='')
    {
      this.toastr.error(
        'Please select table and table fields!',
        notificationMessage.ERROR
      ); 
      return
    }

    if(( data.Fields.length==0 )&&this.queryName=='')
    {
      this.toastr.error(
        'Please select table and table fields!',
        notificationMessage.ERROR
      ); 
      return
    }

    if((data.Criteria.length==0)&&this.queryName=='')
    {
      this.toastr.error(
        'Please set a valid where clause!',
        notificationMessage.ERROR
      ); 
      return
    }
    if((data.TableName=="" ))
    {
      this.toastr.error(
        'Please select table and table fields!',
        notificationMessage.ERROR
      ); 
      return
    }

    if(( data.Fields.length==0 ))
    {
      this.toastr.error(
        'Please select table and table fields!',
        notificationMessage.ERROR
      ); 
      return
    }

    if((data.Criteria.length==0))
    {
      this.toastr.error(
        'Please set a valid where clause!',
        notificationMessage.ERROR
      ); 
      return
    }


    var filtModelsLoc: FilterModel[]=[];
    var fieldsLoc:string[]=[];
    var newData:QueryModel={          
      TableName: "",
      Fields: fieldsLoc,
      Criteria: filtModelsLoc,
      UserName:"",
      QueryType:"",
      QueryName:""};

    newData.QueryName=data.QueryName;
    newData.QueryType=data.QueryType;
    newData.TableName=data.TableName;
    newData.UserName=data.UserName;

    data.Criteria.forEach(qCriet=>{

      var crieteriaFound: Boolean = false;
      newData.Criteria.forEach(newCriteria=>{
        
        if(newCriteria.ConditionalOperator==qCriet.ConditionalOperator
          && newCriteria.Field==qCriet.Field &&
          newCriteria.LogicalOperator==qCriet.LogicalOperator &&
          newCriteria.Value==qCriet.Value && !crieteriaFound)
          crieteriaFound=true;
      })


      if(!crieteriaFound)
      {
        newData.Criteria.push(qCriet);
      }
    })

    data.Fields.forEach(qField=>{

      var fieldFound:Boolean = false;
      newData.Fields.forEach(newField=>{
        if(newField==qField && !fieldFound)
        fieldFound= true;
      })
      if(!fieldFound)
      {
        newData.Fields.push(qField);
      }

    })


    //progress bar
    //set progress Bar Counter
    var progressBarCounter:number=0;
    
    this.adminService.runQuery(newData,this.pageNumber,this.rowsPerPage,true).subscribe((dataBack: any) => {

      var resultData: any;
      var rowsCount: number=0;
      var pageCount: number=1;
      if(String(dataBack.body.responseData)=="[]")
      {
        this.toastr.info(
          notificationMessage.QUERY_NO_DATA_FOUND,
          notificationMessage.SUCCESS
        );
        this.queryRowsCountMessage = "Total Rows Count="+String(this.queryResultsRowsCount);
        this.progressBarFullTeamLabel = "";
        return;
      }

      resultData = [];
      rowsCount = dataBack.body.rowsCount;
      //if page count from api = 0 then set it to 1
      if(dataBack.body.pageCount>0)
      {
        pageCount = dataBack.body.pageCount;
      }
      else
      {
        pageCount = 1;
      }

      //notify user if rows count is 0
      if(dataBack.body.rowsCount==0)
      {
        this.toastr.info(
          'Query Returned No Data!',
          notificationMessage.SUCCESS
        );
      }


      if(dataBack && dataBack.status==200)
      {
        this.progressBarFullTeamValue = Number(parseInt(String((progressBarCounter+1)*100/pageCount)));
        //show BAR progress
        this.progressBarFullTeamLabel = "Loading Query Data Progress: "+this.progressBarFullTeamValue+"%";

        if(this.progressBarFullTeamValue==100)
        {
          this.toastr.info(
            "Query Data Load is Complete",
            notificationMessage.SUCCESS
          );   
          this.btnCancelQuery = true;        
        }//end if
      }

      if(dataBack.body.responseData) 
      //if(resultData && resultData.length > 0) 
      {

        this.resultGrid = true;
        localStorage["QueryResultsRowsCount"] = rowsCount;
        this.queryResultsRowsCount = rowsCount;
        this.queryRowsCountMessage = "Total Rows Count="+String(this.queryResultsRowsCount);

        console.log('query rowsCount=',rowsCount); 

        //call api to get query data in a loop
        var newQueryResultDataSet: any;
        if(dataBack &&dataBack.body&&dataBack.body.responseData)  
        {
          try
          {
            newQueryResultDataSet= JSON.parse(dataBack.body.responseData);
          }
          catch(e)
          {
            console.error('Query Data Error-',e);
            this.toastr.error(
              'Query Data Error-'+e,
              notificationMessage.ERROR
            ); 
          }
        }    

        for(var indexi=0;indexi<pageCount;indexi++)
        //for(var indexi=0;indexi<5;indexi++)
        {

          try 
          {
 
              //setRowsCount = false;
              if(progressBarCounter==pageCount-1)
              {
                setRowsCount=true;
               
              }
              else
              {
                setRowsCount = false;
              }  
              

              if(indexi>=1)
              this.adminService.runQuery(newData,indexi+1,this.rowsPerPage,setRowsCount).subscribe((dataBackPrime: any) => {
                if(this.cancelQueryLoad==true)
                {
                  return;
                }

                //progress bar
                if(dataBackPrime && dataBackPrime.status==200)
                {
                  progressBarCounter++;
                  this.progressBarFullTeamValue = Number(parseInt(String((progressBarCounter+1)*100/pageCount)));
                  //show BAR progress
                  this.progressBarFullTeamLabel = "Loading Query Data Progress: "+this.progressBarFullTeamValue+"%";
                }
                
                if(progressBarCounter==1)
                {
                  this.toastr.info(
                    "Query Data Load is Starting...",
                    notificationMessage.SUCCESS
                  );
                }

                var newResSet:any;
                if(dataBackPrime &&dataBackPrime.body&&dataBackPrime.body.responseData)
                {                 
                  try
                  {
                    newResSet = JSON.parse(dataBackPrime.body.responseData);
                  }
                  catch(e)
                  {
                    console.error('Query Data Error-',e);
                    this.toastr.error(
                      'Query Data Error-'+e,
                      notificationMessage.ERROR
                    ); 
                  }
                }
                 

                newResSet.forEach(i=>{
                  //console.log('oops = ',i); 
                  newQueryResultDataSet.push(i);
                })
                
                if(this.progressBarFullTeamValue==100)
                {
                  this.toastr.info(
                    "Query Data Load is Complete",
                    notificationMessage.SUCCESS
                  );              
                }//end if


              });
          } 
          catch (e) //catch code errors
          {
   
            console.error('Query Load Error-',e);
          }

              
        }//end of for loop
        //console.log('query.component newQueryResultDataSet=',newQueryResultDataSet)

        this.resultMakeFinalSet(newQueryResultDataSet);       


      } 
      else 
      {
        localStorage["QueryResultsRowsCount"] = rowsCount;
        this.queryResultsRowsCount = rowsCount;

        this.toastr.info(
          'Query Returned no Results',
          notificationMessage.SUCCESS
        );
      }
      this.resultSection(resultData);
    });//end of first page api call


  }//end runQuery 

  saveQuery(data, type){
    var isDuplicate = true;
    
    this.myQueriesLists.forEach(e => {
      if(e == data.queryName) {
        return isDuplicate = false
      }
    });
    
    if(this.queryTypeFlag == "new") {
      if(isDuplicate) {
        if(type == 'myQuery') {
          this.saveMyQuery(data)
        } else {
          this.saveSharedQuery(data)
        }
      } else {
        this.toastr.error(
          'name already exist, please choose different name',
          notificationMessage.ERROR
        );
      }
    } else if(this.queryTypeFlag == "private") {
      if(data.userName == JSON.parse(localStorage.getItem('userInfo')).userName) {
        if(type == 'myQuery') {
          this.saveMyQuery(data)
        } else {
          if(isDuplicate) {
            this.saveSharedQuery(data)
          }else {
            this.toastr.error(
              'name already exist, please choose different name',
              notificationMessage.ERROR
            );
          }
        }
      } else {
        if(type == 'myQuery') {
          if(isDuplicate) {
            this.saveMyQuery(data)
          }else {
            this.toastr.error(
              'name already exist, please choose different name',
              notificationMessage.ERROR
            );
          }
        } else {
          if(isDuplicate) {
            this.saveSharedQuery(data)
          }else {
            this.toastr.error(
              'name already exist, please choose different name',
              notificationMessage.ERROR
            );
          }
        }
      }
    } else {
      if(data.userName == JSON.parse(localStorage.getItem('userInfo')).userName) {
        if(type == 'myQuery') {
          if(isDuplicate) {
            this.saveMyQuery(data)
          }else {
            this.toastr.error(
              'name already exist, please choose different name',
              notificationMessage.ERROR
            );
          }
        } else {
          this.saveSharedQuery(data)
        }
      } else {
        if(type == 'myQuery') {
          if(isDuplicate) {
            this.saveMyQuery(data)
          }else {
            this.toastr.error(
              'name already exist, please choose different name',
              notificationMessage.ERROR
            );
          }
        } else {
          if(isDuplicate) {
            this.saveSharedQuery(data)
          }else {
            this.toastr.error(
              'name already exist, please choose different name',
              notificationMessage.ERROR
            );
          }
        }
      }
    }


  }//end saveQuery

  saveSharedQuery(data: QueryModel) {
    // console.log("query.component saveSharedQuery() data = "); console.log(data);
    var foundQuery = false;

    if(this.sharedQueriesLists.length > 0) {
      this.sharedQueriesLists.forEach(e => {
        if(e.QueryName == data.QueryName && !foundQuery) //disabled on 5/11/2022
        foundQuery = true;
      })
    }
        //if(data.QueryName && data.QueryName!=="") 
        if(foundQuery)
        {
          if(confirm("Do you want to replace the existing Query?")) {
            this.adminService.SaveSharedQuery(data).subscribe((databack: any) => {
              if(databack.body.values == 'Success') {
                this.toastr.success('Shared Query Saved Successfully ', notificationMessage.SUCCESS);
                this.closebutton.nativeElement.click();
                setTimeout(() => {
                  window.location.reload();
                }, 3000);
              }
              else
              {
                if(databack && databack.body.values && databack.body.responseMsg)
                {
                  this.toastr.error(databack.body.responseMsg, notificationMessage.ERROR);
                this.closebutton.nativeElement.click();
                }
                else
                {
                this.toastr.error('Database Error', notificationMessage.ERROR);
                this.closebutton.nativeElement.click();
                }
              }
              setTimeout(() => {
                window.location.reload();
              }, 5000);
            });
          } else {

            setTimeout(() => {
              window.location.reload();
            }, 500);
          }
        } else {
          this.adminService.SaveSharedQuery(data).subscribe((databack: any) => {
            //console.log('query.component databack=',databack)
            if(databack.body.values == 'Success') {
              this.toastr.success('Shared Query Saved Successfully ', notificationMessage.SUCCESS);
              this.closebutton.nativeElement.click();
              setTimeout(() => {
                window.location.reload();
              }, 3000);
            }
            else
            {
                //console.log('error=',databack);
                if(databack && databack.body.values && databack.body.responseMsg)
                {
                  this.toastr.error(databack.body.responseMsg, notificationMessage.ERROR);
                this.closebutton.nativeElement.click();
                }
                else
                {
                this.toastr.error('Database Error', notificationMessage.ERROR);
                this.closebutton.nativeElement.click();
                }
                setTimeout(() => {
                  window.location.reload();
                }, 5000);

            }
          });
        }
    
  }//end saveSharedQuery

  saveMyQuery(data: QueryModel) {
    //  console.log("query.component saveMyQuery() data = "); console.log(data);
    var foundQuery = false;

    if(this.myQueriesLists.length > 0) {
      this.myQueriesLists.forEach(e => {
        if(e.QueryName == data.QueryName && !foundQuery) //disabled on 5/11/2022
        foundQuery= true;
      })
    }

        if(foundQuery) 
        {
          if(confirm("Do you want to replace the existing Query?")) {
            this.getSaveMyQuerySubscription = this.adminService.SaveMyQuery(data).subscribe((databack: any) => {
              if(databack.body.values == 'Success') {
                this.toastr.success('My Query Saved Successfully ', notificationMessage.SUCCESS);
                this.closebutton.nativeElement.click();
                setTimeout(() => {
                  window.location.reload();
                }, 3000);
              } 
              else
              {
                  if(databack && databack.body.values && databack.body.responseMsg)
                  {
                    this.toastr.error(databack.body.responseMsg, notificationMessage.ERROR);
                  this.closebutton.nativeElement.click();
                  }
                  else
                  {
                  this.toastr.error('Database Error', notificationMessage.ERROR);
                  this.closebutton.nativeElement.click();
                  }
                  setTimeout(() => {
                    window.location.reload();
                  }, 5000);
              }
            });

          } {
            // console.log('operation cancel');
            setTimeout(() => {
              window.location.reload();
            }, 500);
          }
        } else {
          this.getSaveMyQuerySubscription = this.adminService.SaveMyQuery(data).subscribe((databack: any) => {
            //console.log('query.component databack=',databack)
            if(databack.body.values == 'Success') {
              this.toastr.success('My Query Saved Successfully ', notificationMessage.SUCCESS);
              this.closebutton.nativeElement.click();
              setTimeout(() => {
                window.location.reload();
              }, 3000);
            } 
            else
            {
              //console.log('error=',databack)
              if(databack && databack.body.values && databack.body.responseMsg)
              this.toastr.error(databack.body.responseMsg, notificationMessage.ERROR);
              else
              this.toastr.error('Database Error', notificationMessage.ERROR);
              setTimeout(() => {
                window.location.reload();
              }, 5000);
            }
          });
        }

  }//end saveMyQuery

  getQueryDetails(list: QueryModel, qryType) {
    

    if(qryType=='private') this.selectedIndex = this.myQueriesLists.indexOf(list);
    else
    this.selectedIndex = this.sharedQueriesLists.indexOf(list);


    if(qryType == 'private') {
      this.btnSaveCopy = true
      this.btnMyQry = false;
      this.btnSharedQry = false;
      this.queryTypeFlag == "private"
    } else {    
      if(list.UserName == this.userInfo.unique_name) {
        this.btnMyQry = false;
        this.btnSharedQry = false;
        this.btnSaveCopy = true
      } else {
        this.btnMyQry = true;
        this.btnSharedQry = true;
        this.btnSaveCopy = false
      }
      this.queryTypeFlag == "shared"
    }
    this.resetForm();
 
    var userinfo = JSON.parse(localStorage.getItem("userInfo"));;
    let username = "";

    //assign values
    if(userinfo)
    {
      username = userinfo.unique_name;
    }

    var dataIN = {
      queryName: list.QueryName,
      userName: list.UserName,
      type: list.QueryType
    }

    if(dataIN.userName=='' || !dataIN.userName)
    {
      dataIN.userName=username;
    }

    this.getQueryDetailsSubscription = this.adminService.GetQueryDetails(dataIN).subscribe((data: any) => {
      //console.log("query.component this.adminService.GetQueryDetails() data = "); console.log(data);
      //reset query page to 1
      localStorage["QueryPageNumber"] = 1;
      this.pageNumber=1;
 
      this.queryModel = data;
      let  queryModel:QueryModel = data;


      if(queryModel.Fields)
      if(queryModel.Fields.length > 0) {
        
        if(queryModel.Fields[0] != null){
          var Fields = queryModel.Fields[0].split(',');

          //if(Fields.length > 1) //disabled on 5/9/2022
          if(Fields.length > 0)
          {
            Fields.forEach((element, i) => {

              if(i != 0 ) //disabled on 5/9/2022
              if(this.table[0].id==queryModel.TableName)
              {

                var fieldName = element.replace("bis", "");
                fieldName = fieldName.replace(/_/gi, " ");
                var field = {name:fieldName, id:element}

                this.aliasQueryTable.push(
                  this.formBuilder.group({

                    table: [{
                      id: this.table[0].id,
                      name: this.table[0].name
                    }],field: [field]
                  })

                );
                
              }

              
              var tableName = "";
              if(this.table[0].id==queryModel.TableName)
              tableName = this.table[0].name;

              //tableName = tableName.replace(/_/gi, " ");
              var table = {name:tableName, id:queryModel.TableName}
              var fieldName = element.replace("bis", "");
              fieldName = fieldName.replace(/_/gi, " ");
              var field = {name:fieldName, id:element}
              this.aliasQueryTable.at(i).get("table").patchValue(table);
              this.aliasQueryTable.at(i).get("field").patchValue(field);

            });

            // console.log("query.component this.adminService.GetQueryDetails() this.aliasQueryTable = "); console.log(this.aliasQueryTable);
          }
        }
      }
      
      if(queryModel.Criteria)
      {
        //console.log("query.component getQueryDetails() queryModel.Criteria = ",queryModel.Criteria); 
          queryModel.Criteria.forEach((element, i) => {


            if(i != 0 ) {
              this.aliasQueryCondition.push(
                this.formBuilder.group({
                  condition: [{name:"AND"},{name:"OR"}],
                  whereFields: this.whereFields,
                  operator: [{name:'='},{name:'!='},{name:'>'},{name:'>='},{name:'<='},{name:'<'},{name:'contains'}],
                  value: [],
                })
              );
            }
            var fieldName = element.Field.replace("bis", "");
            fieldName = fieldName.replace(/_/gi, " ");
            var value = element.Value.replace("bis", "");
            value = value.replace(/_/gi, " ");
            var field = {id:element.Field, name: fieldName}
            var condition = {name:element.LogicalOperator}
            var operator = {name:element.ConditionalOperator}
            var valueData:any = {id:element.Value, name:value}

            //this.aliasQueryCondition.at(i).get("value").patchValue(valueData);
            //contains
            if(element.ConditionalOperator=='like' || element.ConditionalOperator=='contains')
            {
              //update value
             valueData = String(element.Value).replace(new RegExp('%', 'g'),'');
             operator.name='contains';

             this.aliasQueryCondition.at(i).get("condition").patchValue(condition);
             this.aliasQueryCondition.at(i).get("whereFields").patchValue(field);
             this.aliasQueryCondition.at(i).get("operator").patchValue(operator);
             this.onchangeWhereFields('', i, element.Field)

             this.aliasQueryCondition.at(i).get("value").patchValue(valueData);
              
             this.newForm = this.aliasQueryCondition.at(i);
             //this.onContainSelect(null,i,this.newForm);

             this.aliasQueryCondition.at(i).get("value").patchValue(valueData);


            }
            else
            {
              this.aliasQueryCondition.at(i).get("condition").patchValue(condition);
              this.aliasQueryCondition.at(i).get("whereFields").patchValue(field);
              this.aliasQueryCondition.at(i).get("operator").patchValue(operator);
              this.onchangeWhereFields('', i, element.Field)
              this.aliasQueryCondition.at(i).get("value").patchValue(valueData);
            }



        });
      }


      // console.log("query.component this.adminService.GetQueryDetails() this.aliasQueryCondition = "); console.log(this.aliasQueryCondition);
      this.queryName = queryModel.QueryName;

      this.progressBarFullTeamValue = 0;
      this.progressBarFullTeamLabel = "";
      localStorage["QueryResultsRowsCount"] = 0;
      this.queryResultsRowsCount = 0;
      this.queryRowsCountMessage = 'Please Run this query to see query rows count!';
    });

    setTimeout(() => {
      //don't run the query until user clicks runQuery button
      //this.dataBinding('runQuery');
      
    }, 500);
  }//end getQueryDetails

  exportCSV() {
    //REMOVED on 11/3/2022
    var isValid = false//this.dataBinding('runQuery');
    //end of REMOVED on 11/3/2022

    if(this.paResultData.length>0)
    {
      isValid = true;
    }

    setTimeout(() => {
      // console.log(this.paResultData, "--paResultData")
      var data = this.paResultData;  
      // console.log('@@@@@ paResultData = ');console.log(this.paResultData);
    
      const options = { 
        fieldSeparator: ',',
        quoteStrings: '"',
        decimalSeparator: '.',
        showLabels: true, 
        showTitle: false,
        title: '',
        useTextFile: false,
        useBom: true,
        useKeysAsHeaders: true,
        // headers: ['Column 1', 'Column 2', etc...] <-- Won't work with useKeysAsHeaders present!
      };     
      const csvExporter = new ExportToCsv(options);     
      if(isValid != false ) {
        csvExporter.generateCsv(data);
      }
      
    }, 500);
  }

  onUserRowSelect(event) {
    this.selectedRows = event.selected;
  }
  onPagination(event) {
    const start = (event - 1) * 25;
    const end = event * 25 - 1;
    this.pagination = { start: start, end: end };
  }

  onOperatorSelect(event,index){
      // this.aliasQuery.at(index).get('value').patchValue('');
  }

}
